import React, { useEffect } from "react";
import Main from "../components/Main";
import Nav from "../components/Nav";
import aboutUsTags from "./aboutUsTags.json";
import Seo from "../components/Seo";
import Footer from "../components/Footer";
import {
  Flex,
  Heading,
  Text,
  Image,
  ListItem,
  UnorderedList,
  ListIcon,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
//@ts-ignore
import amonite from "../images/testimonials/amonite.svg";
//@ts-ignore
import bgtestimonials from "../images/testimonials/bgtestimonials.svg";
import { CssBaseline } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { TimelineOppositeContent } from "@material-ui/lab";
import { TimelineCard } from "../components/TimelineCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default function about_us() {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo(".services", { opacity: 0 }, { opacity: 1, duration: 1 })
      .fromTo(".subHeading", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".lotus", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".lotusIphone", { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  return (
    <>
      <Seo
        title={aboutUsTags.title}
        description={aboutUsTags.description}
        url={aboutUsTags.url}
      />
      <CssBaseline />
      <Main>
        <Nav active="about_us" />
        <Flex
          w="100%"
          justifyContent="space-evenly"
          alignItems="center"
          wrap="wrap-reverse"
          backgroundImage={bgtestimonials}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
          backgroundPosition={["-19rem", "-15rem", "-3rem", "-4rem"]}
          px="2rem"
          className="amoniteHead"
          my="5rem"
        >
          <Image
            src={amonite}
            objectFit={"contain"}
            transform={"rotate(45deg)"}
            className="amonite"
            boxSize={"500px"}
            display={["none", "none", "block", "block", "block"]}
          />
          <Flex
            justifySelf={"start"}
            flexDir="column"
            maxW="600px"
            w={["100%", "100%", "40%"]}
          >
            <Heading
              color="#06667C"
              fontSize="3rem"
              fontFamily="Montserrat"
              className="services"
            >
              Our Story
            </Heading>
            <Text
              className="subHeading"
              fontFamily="Open Sans"
              fontSize="1.25rem"
              color={"#727272"}
              my="3rem"
            >
              We are Rogomi Inc., a Philippine-based corporation focused on
              enabling enterprises to tap the power of digital technologies. We
              are one of the pioneers of mobile app development in the
              Philippines - we developed and published our first app on the App
              Store in 2009 and on Google Play in 2010.
              <br />
              <br />
              From a three-man startup developing and releasing mobile
              applications under its own brand, we have now successfully
              published hundreds of mobile applications for start ups and the
              world’s largest brands.
            </Text>
          </Flex>
        </Flex>
        <Flex
          display={["none", "none", "flex", "flex", "flex"]}
          w={["100%", "100%", "80%", "80%"]}
          mb="5rem"
          alignItems="flex-start"
        >
          <Timeline>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2009
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "100px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Founded Numlock Solutions Co.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Released first iPhone apps WalletWhiz and DeskWhiz
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2010
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "200px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Moved to Ortigas office
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Developed first app for a client, Globe Telecom
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Launched MindMash, 1st Philippine-made iPad app
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Launched StreetSmart, winner, Globe Telecom’s Android
                      Challenge for Messaging and Communications
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2011
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "130px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Developed several apps for Ayala Land Premier, Kimberly
                      Clark, Emerson and Cambridge University Press, among
                      others
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2012
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "100px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Opened an office in Iloilo City
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Developed several apps for MSDS.COM. AU (previously Agtech
                      Pty Ltd.)
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2013
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "200px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Dissolved Numlock as a partnership
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Incorporated Rogomi Inc.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Selected as 1 of 10 participants (from over 1,000
                      developers all over the world) to join the Nokia Future/
                      Capture Hackathon in Sweden
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2014
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "100px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Started offering iOS and Android app development training
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Developed and helped startups like Peekawoo and Eat Out
                      Manila launch their mobile apps
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2016
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "20px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Developed and published 200th app
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2018
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "20px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Moved to a shared office both in Manila and Iloilo
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2019
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
                <TimelineConnector style={{ height: "30px" }} />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Transitioned to 100% remote work
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={style.timelineDate}>
                2021
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ width: "2rem", height: "2rem" }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <UnorderedList>
                  <ListItem>
                    <Text style={style.timelineItem}>
                      Successfully delivered our first 100% online collaborative
                      projects from inception to publishing to the App Store &
                      Google Play
                    </Text>
                  </ListItem>
                </UnorderedList>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Flex>
        <Flex
          display={["flex", "flex", "none", "none", "none"]}
          alignItems={"center"}
          w="100%"
          flexDirection="column"
          my="5rem"
        >
          <TimelineCard
            year="2009"
            content={
              <UnorderedList textAlign={"justify"} listStyleType={"none"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Founded Numlock Solutions Co.
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Released first iPhone apps WalletWhiz and DeskWhiz
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2010"
            content={
              <UnorderedList textAlign={"justify"} listStyleType={"none"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Moved to Ortigas office
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Developed first app for a client, Globe Telecom
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Launched MindMash, 1st Philippine-made iPad app
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Launched StreetSmart, winner, Globe Telecom’s Android
                    Challenge for Messaging and Communications
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />

          <TimelineCard
            year="2011"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Developed several apps for Ayala Land Premier, Kimberly
                    Clark, Emerson and Cambridge University Press, among others
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />

          <TimelineCard
            year="2012"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Opened an office in Iloilo City
                  </Text>
                </ListItem>
                <ListItem>
                  <Text style={style.timelineItem}>
                    Developed several apps for MSDS.COM. AU (previously Agtech
                    Pty Ltd.)
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2013"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Dissolved Numlock as a partnership
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Incorporated Rogomi Inc.
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Selected as 1 of 10 participants (from over 1,000 developers
                    all over the world) to join the Nokia Future/ Capture
                    Hackathon in Sweden
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2014"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Started offering iOS and Android app development training
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Developed and helped startups like Peekawoo and Eat Out
                    Manila launch their mobile apps
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2016"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Developed and published 200th app
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2018"
            content={
              <UnorderedList textAlign={"justify"} listStyleType={"none"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Moved to a shared office both in Manila and Iloilo
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2019"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Transitioned to 100% remote work
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
          <TimelineCard
            year="2021"
            content={
              <UnorderedList listStyleType={"none"} textAlign={"justify"}>
                <ListItem>
                  <ListIcon as={CheckIcon} color="#06667c" />
                  <Text style={style.timelineItem}>
                    Successfully delivered our first 100% online collaborative
                    projects from inception to publishing to the App Store &
                    Google Play
                  </Text>
                </ListItem>
              </UnorderedList>
            }
          />
        </Flex>
      </Main>
      <Footer />
    </>
  );
}

const style = {
  timelineItem: {
    fontFamily: "Open Sans",
    fontSize: "1rem",
    color: "#727272",
    display: "inline",
  },
  timelineDate: {
    fontSize: "1.15rem",
    color: "#32aab5",
    fontFamily: "Open Sans",
  },
};
